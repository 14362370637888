import React, {useEffect} from "react";
import scriptLoader from "react-async-script-loader";

const Instagram = ({ isScriptLoaded, isScriptLoadSucceed }) => {
    useEffect(() => {
        const removeUseless = setInterval(() => {
            if(document.getElementsByClassName('eapps-link')[0] !== undefined){
                document.getElementsByClassName('eapps-link')[0].style.display = "none";
            }
            if(document.getElementsByClassName('eapps-widget-toolbar')[0] !== undefined){
                document.getElementsByClassName('eapps-widget-toolbar')[0].style.display = "none";
            }
        }, 1000);
        return () => clearInterval(removeUseless);
    }, []);
    return (
        <div className="elfsight-app-8fcaa178-2ab2-41b2-9fad-8176a72da254"></div>
    );
};

export default scriptLoader(["https://apps.elfsight.com/p/platform.js"])(
    Instagram
);
