import React from 'react'
import { useRef, useState, useEffect } from 'react';

import slideshow1 from './img/pic/brownies.jpg'
import slideshow2 from './img/pic/carrotCake.jpg'
import slideshow3 from './img/pic/devanture.jpeg'
import slideshow5 from './img/pic/latte.jpg'
import slideshow6 from './img/pic/lieu.jpg'
import slideshow7 from './img/pic/max.jpeg'
import slideshow8 from './img/pic/maxmarga.jpeg'
import slideshow9 from './img/pic/repas.jpeg'
import slideshow10 from './img/pic/terrasse.jpeg'

let pics = [slideshow2, slideshow1, slideshow3, slideshow5, slideshow6, 
  slideshow7, slideshow8, slideshow9, slideshow10];


export default function Slideshow() {
  const [index, setIndex] = useState(0);
  const [loadedImages, setLoadedImages] = useState([]);

  const timeoutRef = useRef(null);

  useEffect(() => {
    const preloadedImages = pics.map((image) => {
      const img = new Image();
      img.src = image;
      return img;
    });
    setLoadedImages(preloadedImages);
  }, []);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
  }

  useEffect(() => {
    shuffleArray(pics)
    pics = pics.slice(0,4)
  }, []);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === 3 ? 0 : prevIndex + 1
        ),
      4000
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div className="slideshow">
      <div
        className="slideshowSlider"
        style={{ transform: `translate(${-index * 100}%)`, transition: "1s linear" }}
      >
        {pics.map((backgroundImg, index) => (
          <div
            className="slide"
            key={index}
          ><img src={loadedImages.length > 0 ? loadedImages[index].src : backgroundImg} alt="café" style={{height:"100%", minWidth:"100%", objectFit:"cover"}}></img></div>
        ))}
      </div>

      <div className="slideshowDots">
        {pics.map((_, idx) => (
          <div
            key={idx}
            className={`slideshowDot${index === idx ? " active" : ""}`}
            onClick={() => {
              setIndex(idx);
            }}
          ></div>
        ))}
      </div>
    </div>
  );
}
