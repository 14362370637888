import React from 'react'
import { useRef, useState } from 'react';

import Drawer from 'react-modern-drawer'
import emailjs from '@emailjs/browser';
import 'react-modern-drawer/dist/index.css'

import logo from './img/logo-transparant.png'
import logoVert from './img/logo-vert.png'
import instagram from './img/instagram.svg'
import facebook from './img/facebook.svg'
import menu from './img/menu.svg'
import loader from './img/loader.gif'
import smiley from './img/smiley.png'
import arrow from './img/arrow.png'
import gm from './img/GM.png'

import './App.css';
import Instagram from "./Instagram";
import Slideshow from "./SlideShow";

function App() {
    const carteRef = useRef(null);
    const locationRef = useRef(null);
    const welcomeRef = useRef(null);
    const hourRef = useRef(null);
    const contactRef = useRef(null);

    const scrollTo = (ref, isCenter = true) => {
        if(isCenter){
            ref.current.scrollIntoView({behavior :"smooth", block: "center"});
        }
        else {
            ref.current.scrollIntoView({behavior :"smooth", block: "start"});
        }
        setIsOpen(false);
    }
    const scrollToCarte = () => scrollTo(carteRef, false);
    const scrollToLocation = () =>  scrollTo(locationRef);
    const scrollToWelcome = () => scrollTo(welcomeRef, false);
    const scrollToHour = () => scrollTo(hourRef);
    const scrollToContact = () => scrollTo(contactRef);

    const [isOpen, setIsOpen] = useState(false)
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }

    const closePopup = () =>{
        document.getElementById("Popup").style.display = "none";
    }

    const sendEmail = (e) => {
        e.preventDefault();
        let popup = document.getElementById("Popup");
        let popupText = document.getElementById("Popup-text");
        let loader = document.getElementById("modal-loader");
        let templateParams = {
            name: document.getElementById("name").value,
            mail: document.getElementById("mailAdress").value,
            message: document.getElementById("message").value,
            reply_to: document.getElementById("mailAdress").value,
        };
        if(templateParams.name === "" ||templateParams.mail === "" ||templateParams.message === "" ||templateParams.reply_to === "" ){
            popupText.innerText = "Merci de remplir tous les champs pour envoyer un email.";
            popup.style.display = "block";
            return;
        }
        loader.style.display = "block";
        emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, templateParams, process.env.REACT_APP_MAILJS_KEY)
            .then(() => {
                loader.style.display = "none";
                popupText.innerText = "Email envoyé avec succès !";
                popup.style.display = "block";
            }, () => {
                loader.style.display = "none";
                popupText.innerText = "Une erreur s'est produite lors de l'envoi de l'email, merci de réessayer.";
                popup.style.display = "block";
            });
    };

    const handleClick = (e) => {
        e.preventDefault();
        const content = e.currentTarget.parentElement.getElementsByClassName("Accordion__content")[0];
        const arrow = e.currentTarget.parentElement.getElementsByClassName("Arrow")[0];
        const accordion = e.currentTarget.parentElement;
        if (content.style.maxHeight) {
            accordion.classList.remove("Accordion__active");
            arrow.classList.remove("Arrow__reverse");
            content.style.maxHeight = null;
        } else {
            accordion.classList.add("Accordion__active");
            arrow.classList.add("Arrow__reverse");
            content.style.maxHeight = content.scrollHeight + "px";
        }
    }

    const schemaData = {
        "@context": "http://schema.org/",
        "@type": "LocalBusiness",
        "name": "MOUNTAINS COFFEE HOUSE",
        "image": "https://mountains-thones.fr/static/media/logo-vert.bf45088a3a401a7c69b9.png",
        "url": "https://mountains-thones.fr/",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "4 rue Blanche",
            "addressLocality": "THONES",
            "postalCode": "74230"
        }
    };

    return (
        <div className="App">
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
        />  
            {/* NAVBAR */}
            <div className='Header'>
                <div className='Header-button' onClick={scrollToWelcome}>
                    ACCUEIL
                </div>
                <div className='Header-button' onClick={scrollToCarte}>
                    LA CARTE
                </div>
                <div className='Header-button' onClick={scrollToLocation}>
                    LOCALISATION
                </div>
                <div className='Header-button' onClick={scrollToContact}>
                    NOUS CONTACTER
                </div>
                <div className='Drawer-button'>
                    <img src={menu} alt='logo menu' onClick={toggleDrawer} className='Drawer-icon'/>
                </div>
            </div>
            <Drawer open={isOpen} onClose={toggleDrawer} direction='right' className='Drawer'>
                <div className='Drawer-header-button' onClick={scrollToWelcome}>
                    ACCUEIL
                </div>
                <div className='Drawer-header-button' onClick={scrollToHour}>
                    NOS HORAIRES
                </div>
                <div className='Drawer-header-button' onClick={scrollToCarte}>
                    LA CARTE
                </div>
                <div className='Drawer-header-button' onClick={scrollToLocation}>
                    LOCALISATION
                </div>
                <div className='Drawer-header-button' onClick={scrollToContact}>
                    NOUS CONTACTER
                </div>
            </Drawer>

            {/* SOCIAL MEDIAS */}
            <div className='Socials-medias'>
                <div className='Socials-medias-logos'>
                    <a href="https://www.instagram.com/mountains_coffee_thones/" target="_blank" rel="noreferrer">
                        <div className="Header-navbar">
                            <img src={instagram} className="Socials-medias-icons" alt='logo lien instagram' />
                        </div>
                    </a>
                    <a href="https://www.facebook.com/Mountains-Coffee-Th%C3%B4nes-104937668934354" target="_blank" rel="noreferrer">
                        <div className="Header-navbar">
                            <img src={facebook} className="Socials-medias-icons" alt='logo lien facebook' />
                        </div>
                    </a>
                </div>
            </div>

            {/* BANNER */}
            <Slideshow/>

            <div className="Home-page">
                <div className="Home-logo">
                    <img src={logo} className="Home-logo-img" alt='logo mountains café'/>
                </div>
            </div>

           
            {/* WELCOME */}
            <div ref={welcomeRef} className="Welcome">
                <div className="Welcome-text">
                    <p>Bienvenue !! </p>
                    <p>
                        Au Mountains Coffee vous trouverez plus que du café, l’idée c’est de vous faire vivre une expérience unique, dans un lieu de convivialité où vous vous sentirez comme chez vous. <br/>
                        Que vous soyez seul, en famille ou entre amis, vous trouverez votre bonheur : petit-déjeuner, déjeuner, goûter, brunch ou bien une simple boisson, sur place ou à emporter, c'est vous qui décidez !
                    </p>
                    <p>
                        A très bientôt au Mountains Coffee,<br/>
                        Marguerite & Maxime
                    </p>
                    <br/>
                    <p ref={hourRef} className="Horaires">
                        <span className="Underline">NOS HORAIRES</span><br/>
                        <span style={{fontSize:"30px"}}>
                            Lundi .... Fermé <br/>
                            Mardi .... 8h00 - 19h <br />
                            Mercredi .... 8h00 - 19h <br />
                            Jeudi .... 8h00 - 19h <br />
                            Vendredi .... 8h00 - 19h <br />
                            Samedi .... 8h00 - 19h <br />
                            Dimanche .... Fermé <br/>
                        </span>
                    </p>
                </div>
            </div>

            {/* MENU */}
            <div ref={carteRef} style={{paddingTop:"2em"}} >
                <div className="Carte">
                    <div className="Carte-text">
                        <h1><span className="Underline" style={{fontSize:"40px"}}>LA CARTE</span></h1>
                        <p>
                            <div className="Accordion">
                                <h2 className="Accordion__intro" onClick={handleClick}>
                                    <span className="Green Bold" >PETITES DOUCEURS</span><img className="Arrow" src={arrow} alt="Arrow"/>
                                    <br/>
                                </h2>
                                <div className="Accordion__content">
                                    <span className="Italic">Pour démarrer la journée, un dessert ou un goûter sucré</span><br/><br/>
                                    <span className="Underline">Tous les jours :</span><br/>
                                    Cookie ..... 2,5€<br/>
                                    Cake citron pavot ..... 3,5€<br/>
                                    Brownie ..... 4€<br/>
                                    Muffin ..... 3,5€<br/>
                                    Carrot cake ..... 4,5€<br/>
                                    Cheese cake framboise <span className="Italic Green TwentyPx">saison estivale</span>..... 3€<br/>
                                    Blondie - pomme caramélisé, glaçage sirop d’érable et cannelle <span className="Italic Green TwentyPx">saison hivernale</span>..... 3,5€<br/>
                                    Granola bowl ..... 7€<br/>
                                    <div className="Italic Green TwentyPx">(Jusqu’à 10h30) Yaourt, granola maison, miel, fruits frais</div>
                                    <br/>
                                    
                                    <span className="Underline">Cet été :</span><br/>
                                    Glaces vegetales De SŸBA <span className="Italic Green TwentyPx">pot de 120ml (2 boules) </span>..... 4,5€ <br/>
                                    Sorbet<br/>
                                    <span className="Italic Green TwentyPx">Citron vert basilic - Melon - Fraise</span><br/>
                                    Glace<br/>
                                    <span className="Italic Green TwentyPx">Vanille aux cinq noix - Chocolat noir 58% - Marron AOP Ardèche, brisures de marrons - Lavande, coulis de pêche - Praliné noisette, éclat de noisette, sauce caramel</span><br/>
                                    <br/>
                                    <span className="Italic">D’autres douceurs à venir découvrir sur place selon nos envies et la saison !</span><br/>
                                </div>
                            </div>
                            <br/>
                            <div className="Accordion">
                                <h2 className="Accordion__intro" onClick={handleClick}>
                                    <span className="Green Bold" >LES MIDIS</span><img className="Arrow" src={arrow} alt="Arrow"/><br/>
                                </h2>
                                <div className="Accordion__content">
                                    <span className="Italic">Dès 11h30 jusqu’à écoulement du stock<br/>
                                        Nous essayons de travailler au maximum avec des produits de saison, vous verrez donc notre carte évoluer au fil du temps <img style={{width:"20px"}} src={smiley} alt="smiley"/></span><br/>
                                    <br/>
                                    Wrap Poulet Pesto Parmesan, salade verte ..... 10€<br/>
                                    Quiche, salade verte ..... 7,5€<br/>
                                    Sandwich VG, salade verte ..... 11,5€<br/>
                                    Soupe <span className="Italic Green TwentyPx">saison hivernale</span> .....  Accompagnement : 4€ / Plat : 8€<br/>
                                    Salade composée <span className="Italic Green TwentyPx">saison estivale</span> .....  Accompagnement : 6€ / Plat : 12€<br/>
                                </div>
                            </div>
                            <br/>

                            <div className="Accordion">
                                <div className="Accordion__intro" onClick={handleClick}>
                                    <span className="Green Bold">BOISSONS CHAUDES</span><img className="Arrow" src={arrow} alt="Arrow"/><br/>
                                </div>
                                <div className="Accordion__content">
                                    <br/>
                                    <span className="Underline" >Les caféinés :</span><br/>
                                    Espresso ..... 1,8€<br/>
                                    Déca ..... 1,9€<br/>
                                    Double espresso ..... 3€<br/>
                                    Americano ..... 3€<br/>
                                    Macchiato ..... 2,5€<br/>
                                    Cappuccino ..... 3,5€<br/>
                                    Flat White ..... 3,5€<br/>
                                    Latte ..... 4€<br/>
                                    Latte Macchiato ..... 4,5€<br/>
                                    Mocaccino ..... 4,5€<br/>
                                    Dirty Chaï ..... 4,5€<br/>
                                    <br/>

                                    <span className="Underline" >Méthode douce</span><br/>
                                    <span className="Italic" >Autres méthodes de torréfactions et d’extractions infusées et plus longues, qui donnent davantage de délicatesse qu'un espresso. N’hésitez pas nous demander conseil !</span><br/>
                                    Chemex <span className="Italic Green TwentyPx">6 tasses</span> ..... 8€<br/>
                                    V60 ..... 4€<br/>
                                    <br/>

                                    <span className="Underline" >Les autres boissons maison</span><br/>
                                    Chocolat chaud ..... 4€<br/>
                                    Chaï latte ..... 4€<br/>
                                    Matcha latte ..... 4,5€<br/>
                                    Golden latte ..... 4,5€<br/>
                                    Black latte ..... 4,5€<br/>
                                    Pink latte ..... 4,5€<br/>
                                    Tea fog ..... 4€<br/>
                                    Babyccino ..... 2€<br/>
                                    Baby choco ..... 3€<br/>
                                    Baby pink latte ..... 3,5€<br/>
                                    <br/>

                                    <span className="Underline" >Les extras</span><br/>
                                    Supplément sirop <span className="Italic Green TwentyPx">Vanille ou Caramel</span> ..... 0,5€<br/>
                                    Lait végétal <span className="Italic Green TwentyPx">Avoine Oalty</span> ..... 0,5€<br/>
                                    <br/>

                                    <span className="Underline" >Les thés et infusions</span><br/>
                                    <span className="Italic" >De L’Eden des Thés (bio) </span> ..... 3,5€<br/>

                                    Paradis vert <br/>
                                    <div className="Italic Green TwentyPx">Thé vert de Chine Sencha, China Oolong, morceaux de mangue, papaye, pétales de rose, fleurs de souci, fleurs de bleuet, passiflore</div>
                                    Fruits rouges <br/>
                                    <div className="Italic Green TwentyPx">Thé noir Assam -Inde du Sud, baie de sureau, fraise, groseille, framboise, mûre, pétales de rose</div>
                                    Darjeeling <br/>
                                    <div className="Italic Green TwentyPx">Finest Tippy Golden Flowery Orange Pekoe, bourgeons du Camellia sinensis, musc miellé</div>
                                    Earl grey Russe <br/>
                                    <div className="Italic Green TwentyPx">Thé noir Assam -Inde du Sud, fleurs de souci, fleurs de bleuet</div>
                                    Gunpowder Temple of Heaven <br/>
                                    <div className="Italic Green TwentyPx">Qualité supérieure de Gunpowder avec des feuilles régulièrement enroulées et vertes foncées</div>
                                    China jasmin high grade <br/>
                                    <div className="Italic Green TwentyPx">Thé vert au jasmin</div>
                                    Effluves de printemps <br/>
                                    <div className="Italic Green TwentyPx">Rooibos naturel, écorces d’orange, mangue, fleurs de bleuet </div>
                                    Plongeons aux caraïbes <br/>
                                    <div className="Italic Green TwentyPx">Hibiscus, pomme, coco (21%), cynorrhodon, papaye, morceaux de mangue, écorces d’orange, fleurs de souci, fleurs de bleuet</div>
                                    Maté vert<br/>
                                    <div className="Italic Green TwentyPx">Écorces de citron, gingembre</div>
                                    Domaine des Rêves<br/>
                                    <div className="Italic Green TwentyPx">Camomille, écorce de citron, feuilles framboisier, fleurs de bleuet, lavande, verveine, pomme</div>
                                    Rayon de miel<br/>
                                    <div className="Italic Green TwentyPx">Rooibos naturel, honeybush, camomille, datte, fleurs de soucis, vanille bourbon</div>
                                    Thé vert à la menthe<br/>
                                    <div className="Italic Green TwentyPx">Thé vert de chine Sencha, menthe</div>
                                </div>
                            </div>
                            <br/>

                            <div className="Accordion">
                                <div className="Accordion__intro" onClick={handleClick}>
                                    <span className="Green Bold">BOISSONS FROIDES</span><img className="Arrow" src={arrow} alt="Arrow"/><br/>
                                </div>
                                <div className="Accordion__content">
                                    <br/>

                                    <span className="Underline" >Les maisons :</span><br/>
                                    <span className="Italic" >Selon la saison et nos inspirations !</span><br/>
                                    {/* Smoothie ..... 5€<br/> */}
                                    Jus d’orange ..... 5€<br/>
                                    Citronnade <span className="Italic Green TwentyPx">saison estivale </span>..... 3,5€<br/>
                                    Ice tea maison <span className="Italic Green TwentyPx">saison estivale </span>..... 3,5€<br/>
                                    <br/>

                                    <span className="Underline" >Les softs :</span><br/>
                                    Jus & nectars ..... 4,5€<br/>
                                    <div className="Grey TwentyPx">Abricot Bergeron de la Drôme / Ananas / Fraise / Mandarine / Mangue Alphonso / Poire / Pomme du Tarn & Garonne / Tomate</div>
                                    Thés et infusions glacées <span className="Italic Green">Umà Paris (bio)</span>..... 4€<br/>
                                    <div className="Grey TwentyPx">Thé noir Gingembre Combava / Thé vert Citron Vert Basilic / Rooibos Pêche de Vigne Cannelle</div>
                                    Ginger beer ..... 4€<br/>
                                    Cola ..... 4€<br/>
                                    Limonade <span className="Italic Green">Umà Paris (bio)</span>  ..... 4€<br/>
                                    La Kombucha <span className="Italic Green"> Kokò Kombucha (bio)</span> ..... 4€<br/>
                                    <div className="Grey TwentyPx">Citron Gingembre / Framboise Hibiscus</div>
                                    Les sirops <span className="Italic Green">Bacanha (bio)</span> ..... 2€<br/>
                                    <div className="Grey TwentyPx">Cassis / Fraise / Framboise / Gingembre / Grenadine / Menthe / Passion / Pêche / Poire / Rose / Verveine / Violette / Yuzu</div>
                                    Eau pétillante ..... 3€<br/>
                                    <br/>

                                    <span className="Underline" >Les alcools</span><br/>
                                    Les bières par JEAN-LOUIS (Valence)..... 5€<br/>
                                    <div className="Grey TwentyPx">
                                    Ambrée ou Rousse<br/>
                                    Blanche<br/>
                                    Blonde<br/>
                                    IPA<br/>
                                    </div>
                                    Les vins 12 cl sélectionnés par LA PLANQUE ..... 4,5€<br/>
                                    <div className="Grey TwentyPx">
                                    Rouge<br/>
                                    Blanc<br/>
                                    Rosé<br/>
                                    </div>

                                </div>
                            </div>
                        </p>
                    </div>
                </div>
            </div>

            {/* LOCALISATION */}
            <div ref={locationRef} className="Map">
                <div className="Adresse">
                    <p>MOUNTAINS COFFEE HOUSE<br/>
                    4 rue Blanche<br/>
                    74230 THONES<br/>
                    04 50 27 84 87
                    </p>
                </div>
                <iframe className='Google-map' title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d88864.60807541829!2d6.2678453994729555!3d45.890933041785104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478bed10d0a779df%3A0xedbdb8710c5cbee3!2sMountains%20Coffee%20Th%C3%B4nes!5e0!3m2!1sfr!2sfr!4v1671901868452!5m2!1sfr!2sfr"/>
                {/* <iframe className='Google-map' title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3601.968832584276!2d6.322738154014421!3d45.88135319418083!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478bed6284b142e5%3A0x47c02620a5475984!2s4%20Rue%20Blanche%2C%2074230%20Th%C3%B4nes!5e0!3m2!1sfr!2sfr!4v1656773307740!5m2!1sfr!2sfr"/> */}
            </div>

            {/* INSTAGRAM */}
            <div className="Instagram">
                <Instagram/>
            </div>

            {/* CONTACT */}
            <div ref={contactRef} className="Contact">
                <div className="MailText">
                    <div>
                        <p>VOUS AVEZ UNE QUESTION ?</p>
                        <p className="TwentyPx">Vous pouvez utiliser ce formulaire pour nous adresser un mail </p>
                    </div>
                </div>
                <div className="SendMail">
                    <form onSubmit={sendEmail}>
                        <div className="FormMail">
                            <div className="Field">
                                <label htmlFor="name">NOM</label>
                                <input type="text" name="name" id="name"/>
                            </div>
                            <div className="Field">
                                <label htmlFor="mailAdress">ADRESSE MAIL</label>
                                <input type="text" name="mailAdress" id="mailAdress"/>
                            </div>
                            <div>
                                <label htmlFor="message">MESSAGE</label>
                                <br/>
                                <textarea name="message" id="message" rows="7"/>
                            </div>
                            <div style={{display:"flex"}}>
                                <button id="SendMailButton" type="submit">ENVOYER</button>
                            </div>
                        </div>
                    </form>
                    <div id="modal-loader">
                        <img src={loader} alt="loader" className="loader"/>
                    </div>
                </div>
            </div>

            {/* FOOTER */}
            <div className="Footer">
                <div className="Footer-element" style={{maxWidth: "300px"}}>
                    <img src={logoVert}  style={{width: "100%"}} alt="logo" />
                </div>
                <div className="Footer-element">
                    <div style={{marginTop:"10px"}}>CONTACT
                        <div className="TwentyPx">
                            4 rue Blanche<br/>
                            74230 THONES<br/>
                            04 50 27 84 87<br/>
                        </div>
                    </div>
                </div>
                <div className="Footer-element" style={{textAlign:"center"}}>
                    <div style={{marginTop:"10px"}}>HORAIRES
                    <div className="TwentyPx" >
                    Lundi .... Fermé <br/>
                            Mardi .... 8h00 - 19h <br />
                            Mercredi .... 8h00 - 19h <br />
                            Jeudi .... 8h00 - 19h <br />
                            Vendredi .... 8h00 - 19h <br />
                            Samedi .... 8h00 - 19h <br />
                    Dimanche .... Fermé <br/>
                    </div></div>
                </div>
                <div className="Footer-element" style={{maxWidth: "300px"}}>
                    <img src={gm} alt="Gault & Millau" style={{width: "100%"}}/>
                </div>
                <div className="Footer-element" style={{alignSelf: "end"}} >
                    <p style={{fontSize:"25px"}} > Site réalisé par <a href="https://www.linkedin.com/in/felix-potie/" target="_blank" rel="noreferrer">Félix POTIÉ</a> - 2022</p>
                </div>
            </div>

            <div id="Popup">
                <div id="Close-popup" onClick={closePopup}> x </div>
                <div id="Popup-text">
                </div>
            </div>
        </div>
    );
}

export default App;